import { Button, Group, Modal, Text } from "@mantine/core";
import React, { useState } from "react";
import { Gift } from "tabler-icons-react";
import { detectOS } from "../../utils/helpers";
import BandleBox from "../../ui/BandleBox";

export default function BonusButton({ dict }) {
	const [popupOpened, setPopupOpened] = useState(null);

	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";

	return (
		<>
			<BandleBox header={dict.bonusRound}>
				<Text size="sm">{dict.playBonusRounds}</Text>
				<Button my={8} style={{ backgroundColor: "#2e8a2b" }} radius="xs" rightIcon={<Gift size={18} />}
					onClick={() => setPopupOpened(true)}>{dict.play}</Button>
			</BandleBox>
			{popupOpened &&
				<Modal opened={true} onClose={() => setPopupOpened(false)} withCloseButton={false} >
					<Text weight={700} mb="xl" size={15}>{dict.bonusRounds}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds1}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds1a}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds1b}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds1c}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds2}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds3}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds4}</Text>
					<Text mb={4} size={14}>{dict.bonusRounds5}</Text>
					<Text mb={16} size={14}>{dict.bonusRounds6}</Text>
					<Text size={15}>{dict.bonusRounds7}</Text>
					<Group position="center" mt="xl">
						<Button variant="outline" onClick={() => setPopupOpened(false)}>{dict.noThanks}</Button>
						<Button onClick={() => window.location.href = storeLink}>{dict.playBonusRounds}</Button>
					</Group>
					<Group position="center" mt="xl">
						<img src="\screenshot.png" alt="screenshot" style={{ width: "50%" }} />
					</Group>
				</Modal>
			}
		</>
	);
}