import { useEffect, useState } from "react";

export default function MidnightCountdown() {
	const [timeDiff, setTimeDiff] = useState(null);

	useEffect(() => {
		let midnight = new Date();
		midnight.setHours(24, 0, 0, 0);
		const countDownDate = new Date(midnight).getTime();
		const updateTimeDiff = () => {
			setTimeDiff(countDownDate - new Date().getTime());
		};
		const interval = setInterval(updateTimeDiff, 1000);
		updateTimeDiff();
		return () => clearInterval(interval);
	}, []);

	const clock = () => {
		const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

		if (hours + minutes + seconds < 0) {
			window.location.reload();
			return "00:00:00";
		}
		return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	return clock();
}