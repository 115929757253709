import { Anchor, Group, Text, useMantineColorScheme } from "@mantine/core";
import React from "react";
import { detectOS } from "../../utils/helpers";

export default function OtherGames({ dict }) {
	const { colorScheme } = useMantineColorScheme();
	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";


	return (
		<>
			<Text mt="xl" size={14}>{dict.otherGame}</Text>
			<Group ml={12} mt={4} mb="md" spacing="xl">
				<Group spacing={4}>
					<img src="\logo192.png" width="22" alt="Bandle The App" style={{ borderRadius: 4 }} />
					<Anchor size={14} href={storeLink} target="_blank" weight={500} sx={colorScheme === "dark" ? "color:#C1C2C5; text-decoration: underline" : "undefined"}>Bandle - The App</Anchor>
				</Group>
				<Group spacing={4}>
					<img src="\friends\pop.png" width="22" alt="Popidle" style={{ borderRadius: 4 }} />
					<Anchor size={14} href="https://popidle.the-sound.co.uk/guess-the-album" target="_blank" weight={500} sx={colorScheme === "dark" ? "color:#C1C2C5; text-decoration: underline" : "undefined"}>Popidle</Anchor>
				</Group>
				<Group spacing={4}>
					<img src="\friends\cover.png" width="22" alt="Cover Quest" style={{ borderRadius: 4 }} />
					<Anchor size={14} href="https://coverquest.jbonet.xyz/" target="_blank" weight={500} sx={colorScheme === "dark" ? "color:#C1C2C5; text-decoration: underline" : "undefined"}>Cover Quest</Anchor>
				</Group>
			</Group>
		</>
	);
}