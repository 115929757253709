import { Button, Text } from "@mantine/core";
import React from "react";
import { detectOS } from "../../utils/helpers";
import BandleBox from "../../ui/BandleBox";
import MidnightCountdown from "./midnightCountDown";

export default function MoreBandleButton({ dict }) {
	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";

	return (
		<BandleBox header={<MidnightCountdown />} >
			<Text size="sm">{dict.getApp}</Text>
			<Button my={8} style={{ backgroundColor: "#2e8a2b" }} radius="xs"
				onClick={() => window.open(storeLink, "_blank")}>
				{dict.playMoreBandle}
			</Button>
		</BandleBox>
	);
}