import React, { useEffect, useState } from "react";
import { Text, Stack } from "@mantine/core";
import StatsChart from "../stats/statsChart";
import ShareButton from "./shareButton";
import StatsTable from "../stats/statsTable";
import OtherGames from "./otherGames";
import MoreBandleButton from "./moreBandleButton";
import BonusButton from "./bonusButton";
import MusicAppButtons from "./musicAppButtons";
import Shop from "./shop";

const styles = {
	container: {
		width: "100%", 
		display: "flex", 
		gap: 8, 
		marginTop: 6, 
		marginBottom: 10, 
		paddingTop: 16
	},
	child: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}
};

export default function EndGameStats({ dict, currentStatus, answer }) {
	const [stats, setStats] = useState(false);

	useEffect(() => {
		setStats(JSON.parse(localStorage.getItem("stats")));
	}, [currentStatus?.loose]);

	if (stats) return (
		<Stack spacing={0} mx="md">
			<Stack align="center" spacing={0}>
				<Text size={15}>{currentStatus?.win ? dict.youWin : dict.youLoose} {dict.todaysSong}</Text>
				<Text size={15} weight={500}>{answer.songDisplay || answer.song}</Text>
			</Stack>
			<MusicAppButtons answer={answer} />
			<StatsChart stats={stats} currentStatus={currentStatus} />
			<ShareButton dict={dict} answer={answer} currentStatus={currentStatus} />
			{stats.played > 4 && <Shop dict={dict} />}
			<div style={styles.container}>
				<div style={styles.child}>
					<BonusButton dict={dict} />
				</div>
				<div style={styles.child}>
					<MoreBandleButton dict={dict} />
				</div>
			</div>
			<StatsTable dict={dict} stats={stats} />
			<OtherGames dict={dict} />
		</Stack>
	);
}