import React from "react";
import { Modal, Text, Container, Button, Center } from "@mantine/core";
import { AlertCircle } from "tabler-icons-react";
import { getLang } from "../../utils/helpers";

export default function Message({ dict, message, setMessage }) {

	const lang = getLang();

	return (
		<Modal opened={true} onClose={() => setMessage(null)} title={<Text weight={700} size="lg">{message.title[lang]}</Text>}>
			<table>
				<tbody>
					<tr>
						<td><Text mb="lg"><div dangerouslySetInnerHTML={{ __html: message.body[lang] }} /></Text></td>
					</tr>
					{message.body2 &&
					<tr>
						<td><Text mb="lg">{message.body2[lang]}</Text></td>
					</tr>
					}
				</tbody>
			</table>
			<Center>
				<Button style={{ backgroundColor: "#2e8a2b" }} radius="xs" onClick={() => setMessage(null)}>{dict.play}</Button>
			</Center>
		</Modal>
	);
}