import { useEffect, useState } from "react";
import { crypt, decrypt, formatDate, formatDate2 } from "../utils/helpers";
import { useMantineColorScheme } from "@mantine/core";

export default function useGame() {
	const { toggleColorScheme } = useMantineColorScheme();
	const [currentStatus, setCurrentStatus] = useState(null);
	const [answer, setAnswer] = useState(null);
	const [listenedOnce, setListenedOnce] = useState(false);
	const [message, setMessage] = useState(null);
	const [daySinceLastPlayed, setDaySinceLastPlayed] = useState(0);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const dt = params.get("fd7491") ? new Date(params.get("fd7491")) : new Date();
		const day = formatDate(dt);
		const url = `https://sound.bandle.app/answers${formatDate2(dt)}.txt?d=${Date.now()}`;
		fetch(url).then(async res => {
			const answers = JSON.parse(decrypt("isItReallyWorthIt", await res.text()));
			const theAnswer = answers.find(x => x.day === day);
			if (theAnswer.skin === "halloween") toggleColorScheme("dark");
			setAnswer(theAnswer);
			let status = JSON.parse(localStorage.getItem("status") || "{}");
			if (status.day !== theAnswer.day)
				status = { day: formatDate(new Date()), step: 1, win: false, loose: false };
			if (!status.guesses) status.guesses = [undefined, ...Array(theAnswer.instruments.length).fill("w")];
			setCurrentStatus(status);
			const stats = JSON.parse(localStorage.getItem("stats"));
			if (theAnswer.messageV2 && theAnswer.messageV2.playMin <= (stats?.played || 0)
				&& (!theAnswer.messageV2.platforms || theAnswer.messageV2.platforms.includes("web")))
				setMessage(theAnswer.messageV2);
			setDaySinceLastPlayed(theAnswer.id - (stats?.lastId || 0));
		});
	}, []);

	const updateStatus = status => {
		status = { ...currentStatus, ...status };
		localStorage.setItem("status", JSON.stringify(status));
		setCurrentStatus(status);
	};

	const updateEndGameStats = isWin => {
		let stats = JSON.parse(localStorage.getItem("stats"));
		stats.played++;
		if (isWin)
			stats[`guess${currentStatus.step}`]++;
		else
			stats.lost++;
		stats.previousStreak = stats.currentStreak + 0;
		stats.currentStreak = isWin && stats.lastId + 1 === answer.id ? stats.currentStreak + 1 : 0;
		if (stats.currentStreak > stats.maxStreak) stats.maxStreak = stats.currentStreak;
		stats.lastId = answer.id;
		localStorage.setItem("stats", JSON.stringify(stats));
	};

	const updateGamePlayed = isWin => {
		let played = JSON.parse(decrypt("play3d", localStorage.getItem("played"))) || [];
		if (!played.some(x => x.folder === answer.folder)) {
			played.push({
				folder: answer.folder, count: 1,
				step: isWin ? currentStatus.step : 7,
				date: formatDate(new Date()), par: answer.par,
				year: answer.year
			});
			localStorage.setItem("played", crypt("play3d", JSON.stringify(played)));
			return true;
		}
		played.find(x => x.folder === answer.folder).count++;
		localStorage.setItem("played", crypt("play3d", JSON.stringify(played)));
		return false;
	};

	const loose = guesses => {
		updateEndGameStats();
		updateGamePlayed();
		updateStatus({ win: false, loose: true, guesses: guesses });
		window.gtag("event", "loose", { event_category: "winLoose", event_label: answer.song });
	};

	const win = () => {
		const guesses = currentStatus.guesses;
		guesses[currentStatus.step] = "g";
		updateEndGameStats(true);
		updateGamePlayed(true);
		updateStatus({ win: true, loose: false, guesses: guesses });
		window.gtag("event", "win", { event_category: "winLoose", event_label: answer.song });
		window.gtag("event", `win${currentStatus.step}`, { event_category: "winStep", event_label: answer.song });
	};

	const nextStep = correctArtist => {
		const guesses = currentStatus.guesses;
		if (correctArtist == null)
			guesses[currentStatus.step] = "b";
		else
			guesses[currentStatus.step] = correctArtist ? "y" : "r";
		if (currentStatus.step < answer.instruments.length) {
			updateStatus({ step: currentStatus.step + 1, guesses: guesses });
			if (currentStatus.step < answer.instruments.length - 1) setListenedOnce(false);
		}
		else
			loose(guesses);
	};

	return {
		answer, listenedOnce, currentStatus, message, daySinceLastPlayed,
		nextStep, win, setListenedOnce, setMessage
	};
}