import React from "react";
import { Container, createStyles, Text } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	box: {
		width: "100%",
		height: "100%",
		position: "relative",
		borderRadius: 8,
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#F9DEB2",
		padding: 8,
		textAlign: "center",
		paddingTop: 20,
	},
	header: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.gray[9] : "#f9c879",
		fontSize: 18,
		fontWeight: 500,
		textAlign: "center",
		position: "absolute",
		top: -12,
		left: 12,
		right: 12,
		borderRadius: 8,
	},
}));

export default function BandleBox({ children, header, style }) {
	const { classes } = useStyles();

	return (
		<Container className={classes.box} style={style}>
			<Container className={classes.header}>
				<Text size="md" weight={700}>{header}</Text>
			</Container>
			{children}
		</Container>
	);
}