import React, { useEffect, useState } from "react";
import { Stack, Group, Divider, Text } from "@mantine/core";
import "../../css/game.css";
import { Header } from "../header/header";
import { Steps } from "./steps";
import { Player } from "./player";
import { Guess } from "./guess";
import Hints from "./hints";
import useGame from "../../hooks/useGame";
import Message from "../popups/message";
import YesterdayPopup from "../popups/yesterdayPopup";
import EndGameStats from "../endGame/endGameStats";
import { TopBanner } from "../header/topBanner";

export default function Game({ dict }) {
	const { answer, listenedOnce, currentStatus, message, daySinceLastPlayed,
		nextStep, win, setListenedOnce, setMessage } = useGame();

	const [stepTapped, setStepTapped] = useState(null);
	const [daySinceLastPlayedModalViewed, setDaySinceLastPlayedModalViewed] = useState(false);
	const [state, setState] = useState(0);
	const gameEnded = currentStatus?.win === true || currentStatus?.loose === true;

	useEffect(() => {
		const attemptScrollToStats = () => {
			const endGameStats = document.querySelector(".endGameStats");
			if (endGameStats && endGameStats.clientHeight > 0)
				endGameStats.scrollIntoView({ behavior: "smooth" });
			else
				setTimeout(attemptScrollToStats, 100);
		};
		if (gameEnded) {
			attemptScrollToStats();
		}
	}, [gameEnded]);

	const settingsUpdated = () => { setState(state + 1); };

	const scrollToBottom = () => {
		const gameContent = document.querySelector(".gameContent");
		window.scrollTo(0, gameContent.offsetTop + gameContent.offsetHeight - window.innerHeight + 90);
	};

	const iconFromSkin = () => {
		switch (answer?.skin) {
			case "tv": return "tv.png";
			case "halloween": return "pumpkin.png";
			case "xmas": return "xmas.svg";
			case "love": return "heart.svg";
			case "usa": return "hat.svg";
			default: return null;
		}
	};

	return (
		<div className="gameContent" style={{ scrollBehavior: "smooth" }}>
			<Stack spacing="xs">
				<TopBanner dict={dict} />
				<Header dict={dict} currentStatus={currentStatus} answer={answer} settingsUpdated={settingsUpdated} />
				<Divider />
				<Group position="center" spacing={6}>
					{answer?.skin && <img src={`\\skin\\${iconFromSkin()}`} width="28" alt="theme icon" />}
					<Text size={answer?.skin ? "md" : "lg"}>
						{answer?.skin == "tv" ? dict.guessPlayingTv : dict.guessPlaying}
					</Text>
					{answer?.skin && <img src={`\\skin\\${iconFromSkin()}`} width="28" alt="theme icon" />}
				</Group>
				<Hints dict={dict} answer={answer} settingsUpdated={settingsUpdated} />
				<Steps dict={dict} answer={answer} currentStatus={currentStatus} setStepTapped={setStepTapped} />
				<Player dict={dict} currentStatus={currentStatus} listenedOnce={listenedOnce} setListenedOnce={setListenedOnce}
					answer={answer} stepTapped={stepTapped} setStepTapped={setStepTapped} />
				{!gameEnded && listenedOnce &&
					<Guess dict={dict} answer={answer} nextStep={nextStep} win={win} currentStatus={currentStatus} scrollToBottom={scrollToBottom} />}

				{gameEnded &&
					<EndGameStats dict={dict} currentStatus={currentStatus} answer={answer} />
				}
				<div style={{ marginBottom: "20px" }} />
				{message && <Message dict={dict} message={message} setMessage={setMessage} />}
				{daySinceLastPlayed == 2 && !daySinceLastPlayedModalViewed &&
					<YesterdayPopup dict={dict} closePopup={() => setDaySinceLastPlayedModalViewed(true)} />}
			</Stack>
		</div>
	);
}