import { Button, Center, Text } from "@mantine/core";
import React from "react";
import { ShoppingCart } from "tabler-icons-react";
import BandleBox from "../../ui/BandleBox";

export default function Shop({ dict }) {

	return (
		<Center>
			<div style={{ width: "60%", marginTop: 20 }}>
				<BandleBox header={dict.shop}>
					<Text size="sm">{dict.shopIntro}</Text>
					<Button mt={10} style={{ backgroundColor: "#2e8a2b" }} radius="xs"
						rightIcon={<ShoppingCart size={18} />}
						onClick={() => window.open("https://bandle-shop.fourthwall.com", "_blank")}
					>
						{dict.shopButton}
					</Button>
				</BandleBox>
			</div>
		</Center >
	);
}